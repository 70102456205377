import React, {Component} from 'react';
import LetsShow from './home_page/LetsShow/LetsShow';
import {isDesktop} from 'app/utils';
import MainPageDescription from 'app/components/MainPageDescription';
import ViewMenuGames from 'app/components/pages/home_page/ViewMenuGames/ViewMenuGames';
import PreviewGames from 'app/components/pages/home_page/PreviewGames/PreviewGames';
import X2BonusPromotion from 'app/components/pages/home_page/X2BonusPromotion/X2BonusPromotion';
import PopularGames from 'app/components/pages/home_page/ViewCasinoGames/PopularGames';

class HomePage extends Component {
    render() {
        return isDesktop() && <div className="home-page-wrapper">

            <LetsShow/>

            <X2BonusPromotion/>

            <PreviewGames/>

            <ViewMenuGames/>

            <PopularGames nameForSliderControls="popular-games-home"/>

            <MainPageDescription headingClick/>
        </div>;
    }
}

export default HomePage;
