import React, {ReactNode} from 'react';

interface Props {
    changeAmount: (amount: string | number, isFixed: boolean) => void
    currentBetAmount: number
    bigBlind: number
    pot: number
    playerBank: number
}

const ONE_BLIND = 1;
const TWO_BLINDS = 2;

export default class PokerBetAmountButtons extends React.Component<Props> {
    changeAmount = (amount: number, withoutPlus = false): void => {
        const {changeAmount, currentBetAmount} = this.props;

        changeAmount(withoutPlus ? amount : currentBetAmount + amount, true);
    };

    onPlusOneBlindClick = (): void => this.changeAmount(ONE_BLIND * this.props.bigBlind);

    onPlusTwoBlindClick = (): void => this.changeAmount(TWO_BLINDS * this.props.bigBlind);

    onPotClick = (): void => this.changeAmount(this.props.pot, true);

    onMaxClick = (): void => this.changeAmount(this.props.playerBank, true);

    render(): ReactNode {
        return <div className="poker-bet-amount-buttons">
            <button onClick={this.onPlusOneBlindClick} className="button-adaptive">+1 BB</button>
            <button onClick={this.onPlusTwoBlindClick} className="button-adaptive">+2 BB</button>
            <button onClick={this.onPotClick} className="button-adaptive button-adaptive_casino">POT</button>
            <button onClick={this.onMaxClick} className="button-adaptive button-adaptive_casino">MAX</button>
        </div>;
    }
}
