/* eslint-disable new-cap */
import React, {Component} from 'react';
import {isDesktop, isGuest} from 'app/utils';
import {getToken} from 'app/services/SportbookService';

const HIDE_SUPPORT_TIME = 200;

export default class Sport extends Component {
    autoHideTimer = null;

    componentDidMount():void {
        if (typeof window.Sportsbook !== 'function') {
            return;
        }

        window.Sportsbook('init', {
            container: '#sport-page',
            contentAlignment: {
                bottomOffsetElement: 'footer',
                enabled: isDesktop()
            },
            getAuthToken: (): Promise<string> => getToken(window.USER_UI_DATA.user_id),
            iframeSrc: `${window.SITE_DATA.SPORTBOOK_URL}/${window.CURRENT_LOCALE}`
        });
        window.Sportsbook('setAuthStatus', {authStatus: isGuest() ? 'NOT_LOGGED_IN' : 'LOGGED_IN'});

        this.hideSupport();
    }

    hideSupport = (): void => {
        const support = document.getElementById('hde-contact-container');

        if (support) {
            support.style.display = 'none';
        } else {
            this.autoHideTimer = setTimeout(this.hideSupport, HIDE_SUPPORT_TIME);
        }
    };

    showSupport = (): void => {
        this.autoHideTimer && clearTimeout(this.autoHideTimer);
        const support = document.getElementById('hde-contact-container');

        if (support) {
            support.style.display = 'block';
        }
    };

    componentWillUnmount(): void {
        if (typeof window.Sportsbook !== 'function') {
            return;
        }

        window.Sportsbook('kill');
        this.showSupport();
    }

    render(): React.ReactNode {
        return <div className="sport-page" id="sport-page">
        </div>;
    }
}
