import React, {Component, Fragment} from 'react';
import I18n from 'app/i18n';
import {isGuest} from 'app/utils';
import logEvent from 'app/amplitude/log_event';
import {inject, observer} from 'mobx-react';
import {userStore} from 'app/store/global';
import {openAuth} from 'app/components/auth/auth';
import UserNavBottom from 'site_version/common/UserNavBottom';
import UserBalance from 'site_version/common/UserBalance';
import Get from 'app/utils/Get';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {UserStore} from 'app/store/UserStore';
import UserInterface from 'app/interfaces/UserInterface';
import BonusInterface from 'app/interfaces/BonusInterface';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import UserPromotions from 'site_version/com/userPromotions';
import UserNavSections from 'site_version/com/UserNav';

interface IBalanceData {
    balance: string
    bonus_balance: string
    bonuses?: BonusInterface[]
    name: string
    play_money: string
    points: number
    verified_status: string
    active_bets_sum?: string
}
interface UserMenuProps {
    onClose?: void
    userStore?: UserStore
    userBonusesStore?: UserBonusesStore
}

interface ResponseInterface{
    success: boolean
    amount: string
    alert: string
}

export const restorePlayMoney = (e: React.MouseEvent): void => {
    e && e.preventDefault();
    if (isGuest()) {
        openAuth();
        return;
    }
    new Get({url: '/user/restore_play_money'})
        .execute()
        .then(data => data.ok ? data.json() : toastError(I18n.t('server_error_try_again')))
        .then(data => handleResponse(data));
};

const handleResponse = (data: ResponseInterface): void => {
    if (data.success) {
        const user = {play_money: data.amount} as UserInterface;
        userStore.update({user});
        toastSuccess(data.alert);
    } else {
        toastError(data.alert);
    }
};

@inject('userStore', 'userBonusesStore')
@observer
export class UserMenu extends Component<UserMenuProps> {
    rootElement: React.RefObject<React.Component> = React.createRef();

    userBalance(): IBalanceData {
        const {name, balance,
            play_money, points,
            verified_status, bonus_balance,
            bonuses, active_bets_sum, demo, demo_balance} = this.props.userStore.user;

        return {
            active_bets_sum,
            balance: demo ? String(demo_balance) : balance,
            bonus_balance,
            bonuses,
            name,
            play_money,
            points,
            verified_status
        };
    }

    handleSignOut:() => void = () => logEvent('LOGOUT');

    render(): React.ReactElement {
        const {bonuses} = this.props.userBonusesStore;
        const {onClose, userStore: {user: {freespins_enabled}}} = this.props;
        const free_spins_count = this.props.userBonusesStore.freeSpinsCount();

        return <Fragment>
            <UserBalance {...this.userBalance()} onClose={this.props.onClose} />

            <UserPromotions/>

            <UserNavSections
                bonuses = {bonuses}
                freespins_enabled = {freespins_enabled}
                free_spins_count = {free_spins_count}
                onClose = {() => onClose}
            />

            <UserNavBottom/>
            <div className="user-nav">
                <a
                    className="user-nav__item"
                    href="/egb_users/sign_out"
                    onClick={this.handleSignOut}
                >
                    <span className="user-nav__icon">
                        <svg height="18px" width="18px">
                            <use xlinkHref="#svg-menu-logout"/>
                        </svg>
                    </span>
                    <span>{I18n.t('user.logout')}</span>
                </a>
            </div>
        </Fragment>;
    }
}
