import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    sitOut: () => void
    sitIn: () => void
    satOut: boolean
}

export default class PokerMenuButtonPause extends Component<Props> {
    render(): ReactNode {
        const {satOut, sitOut, sitIn} = this.props;
        const text = satOut ? I18n.t('poker_bet_sit_in') : I18n.t('poker_bet_sit_out');

        return <button className="poker-menu-container-button pause" onClick={satOut ? sitIn : sitOut}>
            <svg className="svg-default"><use xlinkHref="#svg-pause-icon"/></svg>
            <span className="name">{text}</span>
        </button>;
    }
}
