import React from 'react';
import I18n from 'app/i18n';
import {NavLink} from 'react-router-dom';
import {getClassNameActiveLink} from 'app/utils/link_helper';
import {Outlet} from 'react-router';
import {getLinkPath} from 'app/utils';
import {userStore} from 'app/store/global';
import ProfileMenu from 'site_version/com/profileMenu';

export default class MyProfileTabs extends React.PureComponent {
    render(): React.ReactNode {
        return <div className="tabs js-tabs tabs--top">
            <div className="tabs__header js-custom-scroll">
                <div className="tabs__inner__header">
                    <NavLink
                        to={getLinkPath('/my_profile/settings')}
                        className={getClassNameActiveLink}>
                        {I18n.t('settings')}
                    </NavLink>
                    <NavLink
                        to={getLinkPath('/my_profile/bonuses')}
                        className={getClassNameActiveLink}>
                        {I18n.t('bonuses')}
                    </NavLink>
                    {userStore.user.freespins_enabled
                        ? <NavLink
                            to={getLinkPath('/my_profile/freespins')}
                            className={getClassNameActiveLink}>
                            {I18n.t('freespins')}
                        </NavLink>
                        : null}
                    <ProfileMenu/>
                    <NavLink
                        to={getLinkPath('/my_profile/notifications_settings')}
                        className={getClassNameActiveLink}>
                        {I18n.t('notification_settings.title')}
                    </NavLink>
                </div>
            </div>
            <Outlet />
        </div>;
    }
}
