import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    onSitIn?: (position: number) => void
    position?: number
}

export default class PokerButtonTakePlace extends React.Component<Props> {
    render(): ReactNode {
        const {onSitIn, position} = this.props;

        return <span className="poker-button-take-place" onClick={() => onSitIn(position)}>{I18n.t('poker_take_place')}</span>;
    }
}
