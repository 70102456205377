import React, {useEffect, useState} from 'react';
import {Articles} from 'site_version/common/aside/Articles';
import Chat from 'app/components/Chat';
import Survey from 'app/components/Survey';
import MainPageDescription from 'app/components/MainPageDescription';
import PromoActions from 'site_version/com/aside/PromoActions';
import SupportButton from 'app/components/SupportButton';
import Market from './aside/Market';
import HiddenTemple from 'site_version/com/aside/HiddenTemple';
import {isDesktop, checkingPages} from 'app/utils';
import SmartStoriesMobile from 'app/components/pages/home_page/SmartStories/SmartStoriesMobile';
import AsideStories from 'site_version/com/AsideStories';
import Giveaway from 'site_version/com/Giveaway';
import CasinoWins from 'site_version/com/CasinoWins';
import SocialsList from 'site_version/common/aside/SocialsList';
import Apps from 'site_version/common/aside/Apps';
import {isCasinoPage} from 'app/utils/IsCasinoPage';
import {ScrollUp} from 'app/components/ScrollUp';
import {EgbButton} from 'app/components/Button';
import {getElement} from 'app/utils/getElement';
import AsidePoker from 'app/components/poker/poker_aside/AsidePoker';
import {userStore} from 'app/store/global';
import banners from './aside/Banners';
import LetsShow from 'app/components/pages/home_page/LetsShow/LetsShow';
import PopularGames from 'app/components/pages/home_page/ViewCasinoGames/PopularGames';

type AsideProps = {
    hideChat?: boolean;
};

const Aside: React.FC<AsideProps> = ({hideChat}) => {
    const [showWidget, setShowWidget] = useState(false);

    const handleScroll = (event: Event): void => {
        const blockWidth = -60;
        const target = event.target as Document;
        const {top} = target.body.getBoundingClientRect();

        if (top === 0) {
            setShowWidget(false);
        }

        if (getElement('.casino-page') === null) {
            return;
        }

        if (top <= blockWidth) {
            setShowWidget(true);
        } else {
            setShowWidget(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <aside className="aside">
            <div className="aside__inner">
                {!isDesktop() && <SmartStoriesMobile />}

                {isDesktop() && <AsideStories />}

                <PromoActions />

                {!hideChat && <Chat />}

                {userStore.user.experimental_features && <AsidePoker />}

                <div className="support">
                    <SupportButton />
                </div>

                <SocialsList />

                {isCasinoPage() && <CasinoWins />}

                <Giveaway itemClassName="giveaway" />

                <Market />

                {isDesktop() && !checkingPages.isHomePage() && <Articles />}

                {!isDesktop() && checkingPages.isHomePage() && <LetsShow />}

                {!isDesktop() && checkingPages.isHomePage() && <PopularGames nameForSliderControls="popular-games-aside"/>}

                <HiddenTemple />

                <Survey />

                {!window.SITE_DATA.MOBILE_WRAPPER && <Apps />}

                {(!checkingPages.isHomePage() && isDesktop() || !isDesktop()) && <MainPageDescription/>}

                {banners()}
            </div>

            <ScrollUp
                show={showWidget}
                behavior={'instant'}
                element={getElement('.casino-page')}
                icon={<EgbButton name={'back_to_top'} />}
            />
        </aside>
    );
};

export default Aside;
