import UserInterface from 'app/interfaces/UserInterface';
import {action, makeObservable, observable} from 'mobx';
import $ from 'jquery';
import {ArtifactStore} from 'app/store/ArtifactStore';
import {SteamTradeStore} from 'app/store/SteamTradeStore';
import {StoriesStore} from 'app/store/StoriesStore';
import BonusInterface from 'app/interfaces/BonusInterface';
import Get from 'app/utils/Get';
import {getLinkPath} from 'app/utils';

export interface userStoreInterface {
    userStore?: UserStore
}

interface UpdateInterface {
    user?: UserInterface,
    messagesCount?: number
}

interface ConstructorData {
    artifactStore: ArtifactStore
    steamTradeStore: SteamTradeStore
}

export class UserStore {
    artifactStore: ArtifactStore;

    steamTradeStore: SteamTradeStore;

    storiesStore: StoriesStore;

    @observable user: UserInterface = {
        accept_any_coef: null,
        active_bets_count: null,
        active_bets_sum: null,
        artifact_bet: false,
        balance: null,
        banned: false,
        bet_stats: null,
        bonus_balance: null,
        bonuses: null,
        cashout_enabled: null,
        casino_disabled: null,
        casino_first_bet: null,
        chat_name_color_enabled: null,
        confirmation_period_expired: null,
        confirmed: null,
        country: null,
        country_code: null,
        coupon_close: null,
        created_at: null,
        day_of_birth: {
            day: null,
            month: null,
            year: null
        },
        demo: false,
        demo_balance: null,
        deposit_enabled: null,
        email: null,
        enable_incubator: false,
        enable_incubator2: false,
        experimental_features: false,
        first_bet: null,
        first_deposit: null,
        first_name: null,
        freespins_enabled: false,
        full_balance: null,
        full_registered: null,
        fullname: null,
        giveaway_took_part: null,
        giveaway_turnover: false,
        group: null,
        hot_icon_lootbox_enabled: true,
        id: null,
        incubator_bets_rate: null,
        incubator_last_game_id: null,
        incubator_lines_ids: [],
        is_admin: false,
        last_name: null,
        light_mode: true,
        marketing_consent: null,
        messages_count: null,
        name: null,
        notifications: null,
        phone: null,
        play_money: null,
        points: null,
        public_inventory: null,
        restrict_artifact: null,
        site_lang: null,
        sound_enabled: null,
        time_zone: {
            full_name: 'UTC',
            int: null,
            name: 'UTC',
            t: null
        },
        timezone_id: null,
        token: null,
        trade_enabled: null,
        trade_link: null,
        unknown_country_code: null,
        user_id: null,
        verified_status: null
    };

    @observable signUpCountry: string = '';

    @observable messagesCount = 0;

    subscription = null;

    constructor(data: UpdateInterface, {artifactStore, steamTradeStore}: ConstructorData) {
        this.artifactStore = artifactStore;
        this.steamTradeStore = steamTradeStore;
        makeObservable(this);
        this.update(data);
    }

    @action
    setSignUpCountry(signUpCountry: string): void {
        this.signUpCountry = signUpCountry;
    }

    @action
    updateIncubatorLastGame(game_id: number): void {
        this.user.incubator_last_game_id = game_id;
    }

    @action
    addIncubatorLineId(id: number): void {
        this.user.incubator_lines_ids = this.user.incubator_lines_ids.concat(id);
    }

    @action update({user, messagesCount}: UpdateInterface): void {
        if (typeof messagesCount !== 'undefined') {
            this.messagesCount = messagesCount;
        }
        if (typeof user !== 'undefined') {
            this.user = {
                ...this.user,
                ...user
            };
        }
    }

    @action
    updateBonuses(bonuses: BonusInterface[]): void {
        this.user.bonuses = bonuses;
    }

    @action
    updateFirstBet(status: boolean): void {
        this.user.first_bet = status;
        this.storiesStore.updateOnboardingStories();
    }

    @action
    updateHotIconStatus(status: boolean): void {
        this.user.hot_icon_lootbox_enabled = status;
    }

    changeUserHotIconStatus(): void {
        if (this.user.hot_icon_lootbox_enabled) {
            $.ajax('/user/update_hot_icon_status', {
                method: 'POST',
                success: data => this.updateHotIconStatus(data.status)
            });
        }
    }

    fullRegistered(): boolean {
        return this.user.full_registered;
    }

    unknownCountryCode(): boolean {
        const {unknown_country_code} = this.user;
        return unknown_country_code === null
            ? true
            : unknown_country_code;
    }

    updateProfile = (): void => {
        new Get({url: getLinkPath('/my_profile')})
            .execute()
            .then(data => data.json())
            .then(data => this.update({user: data}));
    };

    isIncubatorLineCreator = (lineId: number): boolean => this.user.incubator_lines_ids.includes(lineId);

    listen(payload: UserInterface): void {
        if (payload.banned) {
            window.location.reload();
        }

        this.update({user: payload});
    }
}
