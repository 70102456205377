import React, {ReactNode} from 'react';

interface Props {
    radioId: string
    radioName: string
    radioValue: number
    radionTitle: string
    additionalName?: string
}

export default class PokerPanelRadioButton extends React.Component<Props> {
    render(): ReactNode {
        const {radioId, radioName, radioValue, radionTitle, additionalName} = this.props;

        return <label className={`poker-radio-button ${additionalName}`} htmlFor={radioId}>
            <input
                id={radioId}
                type="radio"
                name={radioName}
                value={radioValue}
            />
            <span className="icon-radio">
                <svg><use xlinkHref="#svg-check"/></svg>
            </span>
            <span className="title-radio">{radionTitle}</span>
        </label>;
    }
}
