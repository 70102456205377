import React, {PureComponent} from 'react';
import {CasinoGameInterface} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import I18n from 'app/i18n';

interface IframeViewControlsProps {
    handleMinimize: () => void
    handlePopup: () => void
    handleClose: () => void
    activeGameToFavorite: () => void
    activeCasinoGame: CasinoGameInterface
    popup: boolean
    minimized: boolean
}

export default class IframeViewControls extends PureComponent<IframeViewControlsProps> {
    render(): React.ReactNode {
        const {handleMinimize, handlePopup, handleClose, activeCasinoGame, activeGameToFavorite, popup, minimized} = this.props;
        return <div className="iframe-view-controls">
            <button
                className="iframe-view-control-button minimizing"
                onClick={handleMinimize}
                title={I18n.t(minimized ? 'expand' : 'minimized')}
            />
            <button
                className="iframe-view-control-button fullscreen"
                onClick={handlePopup}
                title={I18n.t(popup ? 'fullscreen_off' : 'fullscreen_on')}
            >
                <svg className="svg-default">
                    <use xlinkHref={`${popup ? '#svg-full-screen-off' : '#svg-full-screen-on'}`}/>
                </svg>
            </button>
            <button
                className={`iframe-view-control-button favourite${
                    activeCasinoGame && activeCasinoGame.favorite ? ' active' : ''
                }`}
                onClick={activeGameToFavorite}
                title={I18n.t('add_favorites')}
            >
                <svg className="svg-default">
                    <use xlinkHref="#svg-button-favourite"/>
                </svg>
            </button>
            <button className="iframe-view-control-button closing"
                onClick={handleClose}
                title={I18n.t('сlose_game')}
            >
                <svg className="svg-default">
                    <use xlinkHref="#svg-close"/>
                </svg>
            </button>
        </div>;
    }
}
