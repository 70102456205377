import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import I18n from 'app/i18n';
import {SwiperSlide} from 'swiper/swiper-react';
import {getWidth} from 'app/utils/getWidth';
import {CasinoGamesStore} from 'app/store/CasinoGamesStore';
import CasinoGameItem from 'app/components/pages/casino/CasinoGameItem';
import PopularGamesList from './PopularGamesList';
import {getLinkPath} from 'app/utils/link_helper';
import {Link} from 'react-router-dom';
import {scrollToTop} from 'app/utils';
import {loadHomePageGames} from 'app/services/CasinoServices';

interface PropsInterface {
    casinoGamesStore?: CasinoGamesStore
    nameForSliderControls: string
}

interface StateInterface {
    activeClickGame: number
    renderSlider: boolean
    menuGameLength: number
    loading: boolean
}

const STUBS_COUNT = 9;
const PADDING = 55;

@inject('casinoGamesStore')
@observer
export default class PopularGames extends Component<PropsInterface, StateInterface> {
    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    viewMenuSlideElement: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            activeClickGame: null,
            loading: true,
            menuGameLength: 0,
            renderSlider: true
        };
    }

    componentDidMount(): void {
        const {homePageGames} = this.props.casinoGamesStore;

        this.setState({menuGameLength: homePageGames.length}, () => {
            this.setRenderSwiper();
        });
        window.addEventListener('resize', this.setRenderSwiper);
        loadHomePageGames(() => this.setState({loading: false}));
    }

    componentDidUpdate(): void {
        const {homePageGames} = this.props.casinoGamesStore;

        if (homePageGames.length !== this.state.menuGameLength) {
            this.setState({menuGameLength: homePageGames.length});
            this.setRenderSwiper();
        }
    }

    setRenderSwiper = (): void => {
        const viewMenu = this.viewMenuSlideElement.current?.clientWidth;
        const menuList = getWidth('.slider-popular-games .slider-container-free') ||
        getWidth('.slider-popular-games .slider-container-swiper .swiper-wrapper');
        this.setState({renderSlider: viewMenu - PADDING < menuList});
    };

    renderGameItems = (count: number): React.ReactElement => <div className="slider-preloader">
        {Array.from({length: count}, (_, index) => <span key={index} className="slider-preloader-item"/>)}
    </div>;

    setActiveGame = (id: number): void => {
        this.setState({activeClickGame: id});
    };

    deactivateGame = (): void => {
        this.setState({activeClickGame: null});
    };

    render(): React.ReactNode {
        const {renderSlider, loading, activeClickGame} = this.state;
        const {nameForSliderControls} = this.props;
        const {homePageGames} = this.props.casinoGamesStore;
        const empty = !loading && (!homePageGames || homePageGames.length === 0);

        return !empty && <div className="slider-main-container slider-popular-games">
            <div className="slider-main-container-title">
                <h1 className="title-h1">{I18n.t('popular_slots')}</h1>
                <Link to={getLinkPath('/casino')} onClick={scrollToTop} className="button-adaptive button-adaptive_bigger-light">
                    {I18n.t('play')}
                </Link>
            </div>
            <div className="slider-main-container-body" ref={this.viewMenuSlideElement}>
                {homePageGames && homePageGames.length === 0 && loading
                    ? <>{this.renderGameItems(STUBS_COUNT)}</>
                    : <div className={`slider-container-group${renderSlider ? '' : ' free'}`}
                        ref={this.rootElement}>
                        <PopularGamesList isSwiper={renderSlider} nameForControls={nameForSliderControls}>
                            {homePageGames.map(casinoGame => <SwiperSlide key={`casinoGame-all-${casinoGame.id}`}>
                                <CasinoGameItem
                                    {...casinoGame}
                                    showMobileButtons={activeClickGame === casinoGame.id}
                                    setActiveItem={this.setActiveGame}
                                    deactivateGame={this.deactivateGame}
                                    mainPage
                                />
                            </SwiperSlide>)}
                        </PopularGamesList>
                    </div>}
                {!renderSlider && <div className="slider-pagination-shadow"/>}
            </div>
        </div>;
    }
}
