import React, {ReactNode} from 'react';
import PokerPanelButtonFold from 'app/components/poker/poker_controller_panel/PokerPanelButtonFold';
import PokerPanelButtonCheckCall from 'app/components/poker/poker_controller_panel/PokerPanelButtonCheckCall';
import PokerPanelButtonBetRaise from 'app/components/poker/poker_controller_panel/PokerPanelButtonBetRaise';
import PokerPanelButtonPokerGoBet from 'app/components/poker/poker_controller_panel/PokerPanelButtonPokerGoBet';
import PokerBetAmountButtons from 'app/components/poker/poker_controller_panel/PokerBetAmountButtons';
import PokerPanelRadioButton from 'app/components/poker/poker_controller_panel/PokerPanelRadioButton';
import {inject, observer} from 'mobx-react';
import {PokerStore} from 'app/store/PokerStore';
import {PokerController} from 'app/controllers/PokerController';
import {fixed2} from 'app/utils/fixed';

interface Props {
    pokerStore?: PokerStore
    controller?: PokerController
}

@inject('pokerStore', 'controller')
@observer
export default class PokerControllerPanel extends React.Component<Props> {
    state = {
        amount: '0'
    };

    resetAmount = (): void => this.changeAmount(0);

    check = (): void => {
        this.props.controller.check(this.props.pokerStore.currentTable.table_id);
    };

    call = (): void => {
        this.props.controller.call(this.props.pokerStore.currentTable.table_id);
    };

    fold = (): void => {
        this.props.controller.fold(this.props.pokerStore.currentTable.table_id);
    };

    bet = (): void => {
        this.props.controller.bet(this.props.pokerStore.currentTable.table_id, fixed2(this.state.amount), this.resetAmount);
    };

    allIn = (): void => {
        this.props.controller.allIn(this.props.pokerStore.currentTable.table_id);
    };

    changeAmount = (amount: string | number, isFixed = false): void => {
        const regex = /[^0-9.]/u;

        if (regex.test(amount.toString())) {
            return;
        }

        this.setState({amount: isFixed ? fixed2(amount) : amount});
    };

    isCheck = (): boolean => {
        const maxBet = Math.max(...this.props.pokerStore.currentTable.players?.map(item => item.bet) || [0]);

        return this.props.pokerStore.player.bet >= maxBet;
    };

    isAllIn = (): boolean => {
        const {player, currentTable} = this.props.pokerStore;
        const maxBet = Math.max(...currentTable.players?.map(item => item.bet) || [0]);

        return player.chips < maxBet && player.bet < maxBet || Number(this.state.amount) >= player.chips;
    };

    isPlayerTurn = (): boolean => {
        const {currentTable, player} = this.props.pokerStore;

        return player.position === currentTable.action_index;
    };

    renderRadioButtons = (): ReactNode => {
        if (this.props.pokerStore.player.waiting || this.props.pokerStore.player.sat_out) {
            return <></>;
        }

        return <div className="poker-controller-panel">
            <div className="poker-action-buttons">
                <PokerPanelRadioButton
                    radioId="check_fold_action"
                    radioName="poker[check_fold]"
                    radionTitle="Check/Fold"
                    radioValue={1}
                    additionalName="check_or_fold"
                />
                <PokerPanelRadioButton
                    radioId="check_action"
                    radioName="poker[check_fold]"
                    radionTitle="Check"
                    radioValue={1}
                    additionalName="check"
                />
            </div>
        </div>;
    };

    render(): ReactNode {
        const {player, currentTable} = this.props.pokerStore;

        if (!player) {
            return <></>;
        }

        const isPlayerTurn = this.isPlayerTurn();
        const disabled = player.waiting || !isPlayerTurn;
        const {amount} = this.state;

        return <>
            {!player.waiting && !player.sat_out && isPlayerTurn
                ? <div className="poker-controller-panel">
                    <div className="poker-action-buttons">
                        <PokerPanelButtonFold disabled={disabled} onClick={this.fold} />
                        <PokerPanelButtonCheckCall disabled={disabled} onCheck={this.check} onCall={this.call} isCheck={this.isCheck()} />
                        <PokerPanelButtonBetRaise disabled={disabled} onClick={this.bet} onAllIn={this.allIn} isAllIn={this.isAllIn()} />
                    </div>
                    <PokerPanelButtonPokerGoBet changeAmount={this.changeAmount} amount={amount}/>
                    <PokerBetAmountButtons
                        currentBetAmount={Number(amount)}
                        changeAmount={this.changeAmount}
                        bigBlind={currentTable.big_blind}
                        pot={Number(currentTable.pot)}
                        playerBank={player.chips}
                    />
                </div>
                : this.renderRadioButtons()}
        </>;
    }
}
