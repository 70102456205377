import React from 'react';
import UserNav from '../common/UserNav';
import {steamTradeStore} from 'app/store/global';
import BonusInterface from 'app/interfaces/BonusInterface';

const USER_MENU_SECTIONS = [
    {
        icon_height: '16px',
        icon_width: '16px',
        path: '/my_profile/settings',
        svg: '',
        translate: 'profile'
    },
    {
        icon_height: '14px',
        icon_width: '18px',
        path: '/messages',
        svg: 'messages',
        translate: 'messages'
    },
    {
        icon_height: '14px',
        icon_width: '18px',
        path: '/my_bets',
        svg: 'bets',
        translate: 'rates'
    },
    {
        icon_height: '21px',
        icon_width: '21px',
        path: '/my_profile/bonuses',
        svg: 'bonuses',
        translate: 'my_bonuses'
    },
    {
        icon_height: '18px',
        icon_width: '18px',
        path: '/my_profile/prizes',
        svg: 'prizes',
        translate: 'prizes'
    },
    {
        alert: () => steamTradeStore.hasSteamTradesWithWrongLink(),
        icon_height: '20px',
        icon_width: '20px',
        path: '/my_profile/hero',
        svg: 'hero',
        translate: 'my_hero'
    },
    {
        icon_height: '17px',
        icon_width: '18px',
        path: '/my_profile/trades/outgoing',
        svg: 'trades',
        translate: 'my_trades'
    }
];

const FREESPINS_SECTION_POSITION = 3;
const FREESPINS_SECTION = {
    alert: null,
    icon_height: '21px',
    icon_width: '21px',
    path: '/my_profile/freespins',
    svg: 'freespins',
    translate: 'my_freespins'
};

const USER_MENU_SECTIONS_FREESPINS = [
    ...USER_MENU_SECTIONS.slice(0, FREESPINS_SECTION_POSITION),
    FREESPINS_SECTION,
    ...USER_MENU_SECTIONS.slice(FREESPINS_SECTION_POSITION, USER_MENU_SECTIONS.length)
];

interface UserNavSectionsProps {
    bonuses: BonusInterface[];
    freespins_enabled: boolean;
    free_spins_count: number;
    onClose: () => void;
}

const UserNavSections: React.FC<UserNavSectionsProps> = ({bonuses, freespins_enabled, free_spins_count, onClose}) => <UserNav
    sections={freespins_enabled ? USER_MENU_SECTIONS_FREESPINS : USER_MENU_SECTIONS}
    bonuses={bonuses}
    free_spins_count={free_spins_count}
    onClose={onClose}
/>;

export default UserNavSections;
