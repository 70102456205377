import Get from 'app/utils/Get';
import {toastError} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';

export const getToken = async(user_id: number): Promise<string> => await new Get({
    params: {
        user_id
    },
    url: '/sport/token'
})
    .execute()
    .then(response => response.json())
    .then(response => {
        if (response.token) {
            return response.token;
        } else if (response.message) {
            toastError(response.message);
        } else {
            toastError(I18n.t('server_error_try_later'));
        }
        return null;
    });
